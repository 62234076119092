<template>
  <div class="usdtRefund" style="padding-bottom: 20px">
    <!-- <van-nav-bar
      title="虚拟币十返利"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/usdtRefund2_banner.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <!-- <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p><span>贝宝体育全站会员</span>
            </div>
            <div class="title-item"><p>领取规则:</p><span>仅限领一次</span></div>
            <div class="title-item"><p>返还周期:</p><span>每个月返还1次，10个月返还完毕</span></div>
            <div class="title-item"><p>活动时间:</p><span>长期活动</span></div>

          </div>
        </div> -->

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <div
            style="font-size: 14px;color: #333;font-family: PingFang SC;margin: 10px;line-height: 20px;text-align: justify;">
            活动期间，会员按照当前星级范围内所提供的存款档位，使用USDT账户单笔存款USDT金额达到指定档位条件(100/1000/5000/10000)，即可获得与指定存款档位金额相同的100%红利返还，最高可获10000uSDT，机不可失！
          </div>
          <table cellspacing="0">
            <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
              <th style="color: #fff; border-top-left-radius: 10px; border-right: 1px solid #e2d2bf;">
                会员等级
              </th>
              <th style="color: #fff; border-right: 1px solid #e2d2bf;">
                单笔存款
              </th>
              <th style="color: #fff; border-right: 1px solid #e2d2bf;">
                总返利
              </th>
              <th style="color: #fff; 
                      border-top-right-radius: 10px;">
                每月返利
              </th>
            </tr>
            <template v-for="(item, index) in datalist.data">
              <tr>
                <td :rowspan="item.list.length + 1" class="td_left">
                  {{ item.name.split(' ')[0] }} <br />
                  {{ item.name.split(' ')[1] }} <br />
                  {{ item.name.split(' ')[2] }}
                </td>
              </tr>
              <tr v-for="(child, index) in item.list" :key="index">
                <td>
                  {{ child.deposit_money }}USDT<br /><span>({{ child.deposit_money * 7 }}RMB)</span>
                </td>
                <td>
                  {{ child.deposit_money }}USDT<br /><span>({{ child.deposit_money * 7 }}RMB)</span>
                </td>
                <td>
                  {{ child.monthly_rebate_rate }}USDT<br /><span>({{ child.monthly_rebate_rate * 7 }}RMB)</span>
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="4">
                取款要求 :礼金{{ datalist.beat_limit }}倍流水即可取款
              </td>
            </tr>
          </table>

          <p style="color: red; font-size: 14px; margin: 10px">
            备注:申请前，24小时内最后的单笔虚拟币存款
          </p>
          <div class="btn-box">
            <div @click="applyWay">立即领取</div>
            <!-- <div @click="check">查看进度</div> -->
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1</span>
                活动期间根据会员等级范围，最大共设有4档单笔存款特惠，会员可在任选范围内选择其中—档参与，不可重复享受。
              </li>
              <li>
                <span>2</span>
                2.会员单笔存款条件达标时，将立即享受该笔存款金额10%的红利返还，并从下月起，将以红利派发形式分9个月完成其余红利返还，每月返还10%红利，红利将于每月3日18:00前将完成派发，本活动所有红利将派发至您的会员账号;(例如会员A，8月15日参与活动，活动页面选择参与5000USDT存款档位，且单笔存款达到500OUSDT，则会员将立即获得50OUSDT红利返还，并从下月月起,每月3日18:00前将获得当月50OUSDT红利返还，红利返还方式以此类推)
              </li>
              <li>
                <span>3</span>
                本优惠不限制游戏平台，红利仅需8倍流水即可提款，不与线上优惠、洗码优惠共享;
              </li>
              <li>
                <span>4</span>
                此优惠只适用于拥有一个账户的会员，如发现有违规者我们将保留无限期审核扣回礼金及所产生的利润权利；
              </li>
              <li>
                <span>5</span>
                为避免文字理解差异，贝宝体育将保留该活动的最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data () {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      cueTheme: ''

    }
  },
  mounted () {
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')

  },
  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },

    getdata () {
      var _this = this
      var data = '?type=UsdtRefund'
      getdata(data).then((res) => {
        /*  console.log(res); */
        this.datalist = res.message
      })
    },
    changeisshow () {
      clearInterval(this.timer)
      this.isshow = true

      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay() {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'UsdtRefund',
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {

          // 领取
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'UsdtRefund',
          //     },
          //   }
          // })
          uni.postMessage({
            data: {
              urlApply: 'usdtRefundApply'
            }
          })
        }
      })
    },
    check () {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          Toast('该活动仅在app开发,请下载APP')
        } else {
          // 查看进度
          uni.postMessage({
            data: {
              urlApply: 'usdtRefundCheck'
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
@import "../assets/css/rulestyle.less";

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.liveLottery {
  .activityRules {
    position: relative;
    background-color: #fff;
    margin: 10px 15px;
    // padding-bottom: 1px;
    border-radius: 8px;

    .title {
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: #b2987f;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;
      padding-bottom: 5px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 70px;
        font-size: 12px;

        >span {
          text-align: center;
          position: absolute;
          top: 0px;
          left: 0;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;
        }
      }
    }

    table {
      width: calc(100% - 30px);
      margin: 0 15px;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: PingFang SC;
      border-bottom: 2px solid #e2d2bf;
      font-weight: 400;
      color: #684728;

      th,
      td {
        height: 40px;
        padding: 10px 0;
        color: #684728;
        font-size: 13px;
        text-align: center;
      }
    }

    tr:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:nth-child(odd) {
      background-color: #f7f6f3;
    }

    td {
      border-right: 2px solid #e2d2bf;
    }

    td:nth-child(1) {
      border-left: 2px solid #e2d2bf;
    }
  }

  .Rules {
    margin: 10px 15px;
    // margin: 0 20px;
    // margin-top: 15px;
    font-family: PingFang SC;
    border-radius: 8px;
    background-color: #fff;

    .title {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 18px;
        font-size: 13px;
        line-height: 1.5em;

        >span {
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
        }
      }
    }
  }
}

.btn-box {
  padding-bottom: 5px;

  >div {
    font-family: PingFang SC;
    font-weight: 400;

    &:nth-child(1) {
      height: 40px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      margin: 25px 0px 15px 0px;
    }

    &:nth-child(2) {
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #2a6afd;
      color: #418beb;
      margin: 15px 0px 15px 0px;
    }
  }
}</style>
